<!--
 * @Author       : a大师兄
 * @Date         : 2021-10-28 11:02:19
 * @LastEditors  : a大师兄
 * @LastEditTime : 2023-02-03 16:25:35
 * @Description  :轮播图
-->
<template>
	<div class="home-banner-swipe">
		<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
			<van-swipe-item>
				<van-image
					width="100%"
					src="https://img01.yzcdn.cn/vant/apple-1.jpg"
				/>
			</van-swipe-item>
			<van-swipe-item>
				<van-image
					width="100%"
					src="https://img01.yzcdn.cn/vant/apple-2.jpg"
				/>
			</van-swipe-item>
		</van-swipe>
	</div>
</template>

<script>
export default {
    name: 'bannerSwipe'
};
</script>

<style lang='less'>
</style>
