/*
 * @Author       : a大师兄
 * @Date         : 2021-11-20 17:50:49
 * @LastEditors  : a大师兄
 * @LastEditTime : 2023-01-30 09:09:54
 * @Description  : http请求封装，axios配置
 */
import Vue from 'vue';
import axios from 'axios';
import config from '@/env.config.js';

const newAxios = axios.create(
        {
            baseURL: config.baseURL,
            timeout: 50000,
            headers: { 'Content-Type': 'application/json' }
        }
    ),

    err = (response) => {
        if (response.data) {
            switch (response.data.code) {
            case '403':
                console.error({
                    code: `${response.data.code}`,
                    message: '拒绝访问!',
                    errApi: response.config.url
                });
                break;
            case 404:
                console.error({
                    code: `${response.data.code}`,
                    message: '很抱歉，资源未找到!',
                    errApi: response.config.url
                });
                break;
            case 504:
                console.error({
                    code: `${response.data.code}`,
                    message: '网络超时!',
                    errApi: response.config.url
                });
                break;
            case 401:
                console.error({
                    code: `${response.data.code}`,
                    message: '未授权，请重新登录',
                    errApi: response.config.url
                });
                break;
            default:
                console.error({
                    code: `${response.data.code}`,
                    message: `${response.data.msg}`,
                    errApi: response.config.url
                });
            }
        }
        return Promise.reject(response.data);
    };

//http 请求 拦截器
newAxios.interceptors.request.use(
    conf => {
        // if (conf.data&&conf.data.loading) {
        //     Vue.prototype.$toast.loading({
        //         duration: 0, // 持续展示 toast
        //         message: conf.data.loadingMessage || '加载中...',
        //         forbidClick: true
        //     });
        //     delete conf.data.loading;
        //     delete conf.data.loadingMessage;
        // }
        // // 在发送请求之前做些什么
        // if (conf.data instanceof FormData) {
        //     console.log('multi');
        //     conf.headers['Content-Type'] = null;
        // }
        return conf;
    },
    error => {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 响应拦截器
newAxios.interceptors.response.use((response) => {
    console.log('正常接口请求响应的拦截',response,response.data.code == '200' );
    Vue.prototype.$toast.clear();
    if (response.data.code == '200' || response.data.code == '0000') {
        return response.data;
    }
    if (response.data.code == '500') {
        return response.data;
    }
    return err(response);

}, err);

export {
    newAxios as axios
};