<template>
   <van-empty :image="require('@/assets/logo.png')" :description="description"/>
</template>

<script>
export default {
    name: 'ComEmpty',
    components: {},
    props: {
        description: {
            type: String,
            default: '暂无数据'
        }
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {}
};
</script>

<style scoped lang='less'>
</style>
