import Vue from 'vue';
import {
    Empty,
    Button,
    Popup,
    Cascader,
    Form,
    NumberKeyboard,
    Col,
    Row,
    Cell,
    CellGroup,
    Card,
    Notify,
    Lazyload,
    Tab,
    Tabs,
    AddressEdit,
    Field,
    Swipe,
    SwipeItem,
    Tabbar,
    TabbarItem,
    Grid,
    GridItem,
    Skeleton,
    Collapse,
    CollapseItem,
    TreeSelect,
    NoticeBar,
    Loading,
    Overlay,
    Tag,
    Toast,
    Badge,
    Sidebar,
    Image as VanImage,
    List,
    Icon,
    Picker,
    SidebarItem,
    Radio,
    RadioGroup,
    SubmitBar,
    CountDown,
    Popover,
} from 'vant';


Vue.use(Overlay);
Vue.use(Tag);
Vue.use(NoticeBar);
Vue.use(TreeSelect);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Skeleton);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Empty);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Card);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Notify);
Vue.use(Lazyload);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Field);
Vue.use(AddressEdit);
Vue.use(Col);
Vue.use(Row);
Vue.use(NumberKeyboard);
Vue.use(Form);
Vue.use(Button);
Vue.use(Cascader);
Vue.use(Popup);
Vue.use(VanImage);
Vue.use(Loading);
Vue.use(Toast);
Vue.use(Badge);
Vue.use(Sidebar);
Vue.use(List);
Vue.use(Icon);
Vue.use(Picker);
Vue.use(SidebarItem);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(SubmitBar );
Vue.use(CountDown);
Vue.use(Popover);