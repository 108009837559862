import Vue from 'vue';
import App from './App.vue';
import router from './router/router.index';
import store from './store';
import config from '@/env.config.js';
import WxJsSdk from '@/utils/wxSdkShare.js';
import JComponents from '@/components/index';
import $theme from './theme';
import {
    httpAction,
    getAction,
    postAction
} from '@/utils/request.js';

import '@/vantui/vantUi.js';
import '@/utils/resize.js';

import loading from '@/components/loading/vLoading.js';

import animated from 'animate.css';

import { EventBus } from './EventBus';
Vue.prototype.$bus = EventBus;
Vue.directive('loading', loading);
Vue.use(animated);
Vue.use(JComponents);

//将工具类添加到Vue静态方法方便调用
Vue.prototype.$CONFIG = config;
Vue.prototype.$wxJsSdk = new WxJsSdk();
// 主题
Vue.prototype.$theme = $theme;

Vue.config.productionTip = false;
Vue.prototype.$http = {
    httpAction,
    getAction,
    postAction
};
// 是否是微信端
Vue.prototype.$isWeiXin = (function () {
    var ua = navigator.userAgent.toLowerCase();

    return ua.match(/MicroMessenger/i) === 'micromessenger';
})();

Vue.prototype.$hideLoading = function () {
    setTimeout(() => {
        document.getElementById('loading').style.display = 'none';
    }, 1000);
};

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
