<template>
	<div class="v-loading">
		<div v-show="visible" class="v-loading-icon">
			<van-loading :color="$theme.vLoadingIconColor" />
		</div>
		<div v-show="visible" class="v-overlay"></div>
	</div>
</template>

<script>
export default {
    model: {
        prop: 'defaultValue',
        event: 'setValue'
    },
    name:'vLoading',
    props: {
        visible: [Boolean]
    },
    watch: {
        defaultValue(boo) {
            this.loading = boo;
        }
    },
    data () {
        return {
            loading:false
        };
    },
    components: {

    },
    computed:{

    },
    mounted() {
        this.loading = this.defaultValue;
        console.log(this.defaultValue);
    },
    methods: {

    }
};

</script>
<style scoped lang='less'>
.v-loading,.v-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.v-loading-icon{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    margin-top: -16px;
    margin-left: -16px;
}

.v-overlay{
    position: relative;
    z-index: 9;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: .4;
}
</style>