import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    shoppingList: [], // 初始化购物车列表为空数组
    roomInfo:{},
  },
  mutations: {
    UPDATE_SHOPPING_LIST(state, newList) {
      state.shoppingList = newList;
    },
    UPDATE_ROOMINFO(state, newList) {
      state.roomInfo = newList;
    },
    SET_ROOMINFO(state, newList) {
      state.roomInfo = newList;
    },
    SET_SHOPPING_LIST(state, newList) {
      state.shoppingList = newList;
    },
    CLEAR_SHOPPING_LIST(state) {
      state.shoppingList = [];
    },
  },
  actions: {
    updateShoppingListFromStorage({ commit }) {
      const storedList = JSON.parse(localStorage.getItem('upDateShoppingList'));
      if (storedList) {
        commit('UPDATE_SHOPPING_LIST', storedList);
      }
    },
    updateShoppingList({ commit }, newList) {
      commit('SET_SHOPPING_LIST', newList);
    },
    clearShoppingListAndStorage({ commit }) {
      commit('CLEAR_SHOPPING_LIST'); // 先清空 Vuex 状态
      localStorage.removeItem('upDateShoppingList'); // 再清除 localStorage 中的数据
    },
    updateRoomInfoFromStorage({ commit }) {
      const storedList = JSON.parse(localStorage.getItem('upDateRoominfo'));
      if (storedList) {
        commit('UPDATE_ROOMINFO', storedList);
      }
    },
    updateRoominfo({ commit }, newList) {
      commit('SET_ROOMINFO', newList);
    },
  },
  getters: {
    shoppingList(state) {
      return state.shoppingList;
    },
    roomInfo(state) {
      return state.roomInfo;
    },
  }
});