import Vue from 'vue';
import VueRouter from 'vue-router';
import { networkRouters } from './../views/network/router';
import patientRouters from './../views/patient/router';


Vue.use(VueRouter);
const routes = [
        ...patientRouters,
        ...networkRouters,
    ],

    router = new VueRouter({
    //mode: 'history',
        routes
    });

router.beforeEach((to, from, next) => {
    console.log(to, from,'路由变化')
    if (to.meta.title) {
        document.title = to.meta.title;
        // dd.setTitle({
        //     title: to.meta.title,
        // })
        //     .then((res) => {
        //         // alert(2222)
        //         console.log(res,11111);
        //     })
        //     .catch((err) => {
      
        //     });
        //     try {
        //         MFS.initNav({
        //             //中间标题
        //             // title: htmlWebpackPlugin.options.title,
        //             title: to.meta.title,
        //         })
        //         console.log('当前页面title：'+to.meta.title)
        //     } catch (e) {
        //         console.log("发生异常:" + e)
        //     }
    }
    if(from.fullPath!=="/" && to.name == 'home' && to.fullPath!=='/home'){
        next('/home');
    } else{
        next();
    }
});

export default router;
