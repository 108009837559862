export default [
    {
        path: '/',
        name: 'apkHome',
        redirect: '/apkHome',
        meta: {
            index: 1,
            title: '首页',
        }
    },
    {
        path: '/apkHome',
        name: 'apkHome',
        meta: {
            title: '首页',
        },
        component: () => import('./views/home.vue')
    },
    {
        path: '/home',
        name: 'home',
        meta: {
            title: '首页'
        },
        component: () => import('./views/home.vue')
    },
    {
        path: '/menuList',
        name: 'menuList',
        meta: {
            title: '营养菜谱'
        },
        component: () => import('./views/menuList.vue')
    },
    {
        path: '/payDetail',
        name: 'payDetail',
        meta: {
            title: '确认订单'
        },
        component: () => import('./views/payDetail.vue')
    },
    {
        path: '/myOrder',
        name: 'myOrder',
        meta: {
            title: '订单'
        },
        component: () => import('./views/myOrder.vue')
    },
    {
        path: '/orderDetail',
        name: 'orderDetail',
        meta: {
            title: '订单详情'
        },
        component: () => import('./views/orderDetail.vue')
    },
    {
        path: '/pay',
        name: 'pay',
        meta: {
            title: '支付'
        },
        component: () => import('./views/pay.vue')
    },
    {
        path: '/personalCenter',
        name: 'personalCenter',
        meta: {
            title: '个人中心'
        },
        component: () => import('./views/personalCenter.vue')
    },
    {
        path: '/nutritionalAnalysis',
        name: 'nutritionalAnalysis',
        meta: {
            title: '营养分析'
        },
        component: () => import('./views/nutritionalAnalysis.vue')
    },
    {
        path: '/paySearch',
        name: 'paySearch',
        meta: {
            title: '支付查询'
        },
        component: () => import('./views/paySearch.vue')
    },
];