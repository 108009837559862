<!--
 * @Author       : a大师兄
 * @Date         : 2022-02-18 15:36:01
 * @LastEditors  : a大师兄
 * @LastEditTime : 2023-01-30 09:11:23
 * @Description  :
-->
<template>
	<div></div>
</template>

<script>
export default {
    name: 'TabBar',
    components: {},
    props: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {}
};
</script>

<style scoped lang='less'>
</style>
