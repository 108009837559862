/*
 * @Author       : a大师兄
 * @Date         : 2021-11-12 14:20:05
 * @LastEditors  : a大师兄
 * @LastEditTime : 2023-09-12 14:11:31
 * @Description  : 微信相关
 */
const wx = require('weixin-js-sdk');

import Vue from 'vue';
function WxShareSdk(wxShareConfig) {
    this.routerMode = '';
    this.apiUrl = {
        getSignature: '/basic/share/getSignature'
    };
    //存储各个链接的签名信息
    this.signMap = new Map();
    // 路由模式  hash or history
    this.history = 'history';
    // 初始化信息
    this.wxShareConfig = Object.assign({
        title: '分享标题（通常是动态的）',
        desc: '',
        imgUrl: '',
        link: '',
        isShare: false,
        success: function () { console.log('分享回调'); },
        jsApiList: [
            'showMenuItems',
            'hideAllNonBaseMenuItem',
            'updateAppMessageShareData',
            'updateTimelineShareData',
            'onMenuShareTimeline',
            'onMenuShareAppMessage'
        ],
        menuList: [
            'menuItem:share:timeline',
            'menuItem:share:appMessage'
        ]
    }, wxShareConfig);
    Object.defineProperty(this.wxShareConfig, 'wxShareConfig', {
        set: function (value) {
            console.log('有人修改了param属性');
            this.value = value;
        },
        enumerable: false,
        configurable: true
    });
}

WxShareSdk.prototype.setShareParameters = function (config) {
    this.wxShareConfig = Object.assign(this.wxShareConfig,config);
    this.wxShareInit();
};

//各个页面加载后，调用此方法，传入的参数config是对象
WxShareSdk.prototype.wxShareInit = function () {
    //微信中二次分享的处理，截取到有效的分享链接 ---如果是路由是history模式 则获取#之前的真实链接
    var authUrl = this.routerMode === 'history' ? this.wxShareConfig.link.split('#')[0] : window.location.href;
    //判断是否已经签名了

    if (this.signMap.has('authUrl')) {
        this.wxConfigSign(this.signMap.get('authUrl'));
    } else {
        this.wxShareAuth(authUrl);
    }
    //console.log('signMap', signMap.get('authUrl'));
};

//从服务器获取某分享链接的签名信息，并存储起来
WxShareSdk.prototype.wxShareAuth = function (authUrl) {
    //此处我使用的是自己封装的网络请求方法
    const promise = Vue.prototype.$http.postAction(this.apiUrl.getSignature, { url: authUrl });

    promise.then(res => {
        //此处请根据各自的服务器返回数据文档进行操作
        //分享链接授权签名信息
        const sign = res;

        this.signMap.set('authUrl', sign);
        this.wxConfigSign(sign);
    });
    promise.catch((err) => {
        console.log(err.response);
    });
};

//将签名信息更新到微信的SDK中
WxShareSdk.prototype.wxConfigSign = function (shareSign) {
    wx.config({
        beta: true,
        debug: false,
        appId: shareSign.appId,
        timestamp: shareSign.timestamp,
        nonceStr: shareSign.noncestr,
        signature: shareSign.signature,
        jsApiList: this.wxConfig.jsApiList
    });
    // config信息验证后会执行ready方法;
    wx.ready(function () {
        // 隐藏所有非基础按钮接口
        wx.hideAllNonBaseMenuItem();
        // 只显示朋友圈跟发送好友
        if (this.wxConfig.isShare) {
            wx.showMenuItems({
                menuList: this.wxConfig.menuList
            });
            const shareEvent = {
                success: () => {
                    this.wxConfig.success();
                    console.log('分享成功');
                },
                fail: () => {
                    this.wxConfig.fail();
                    console.log('分享失败');
                },
                cancel: () => {
                    this.wxConfig.cancel();
                    console.log('取消分享');
                }
            };

            wx.updateAppMessageShareData({
                ...this.wxConfig,
                ...shareEvent
            });
            wx.updateTimelineShareData({
                ...this.wxConfig
            });
            // 调用老版本接口支持分享回调 ----分享给朋友
            wx.onMenuShareTimeline({
                ...this.wxConfig,
                ...shareEvent
            });
            // 调用老版本接口支持分享回调 ----分享朋友圈
            wx.onMenuShareAppMessage({
                ...this.wxConfig,
                ...shareEvent
            });
        }
        console.log(shareSign);
    });
    // config信息验证失败会执行error函数，
    wx.error(function (res) {
        console.log('分享失败: error', res);
    });
};

// 调用分享案例
// 1. main.js 引入
// import wxJsSdk from '@/utils/wxSdkShare.js';
// Vue.prototype.$wxJsSdk = new wxJsSdk();
// 2.组件调用分享---由于全局已经定义过wxShareSdk 我们可以通过this调用
//this.$wxJsSdk.setShareParameters({
//    title: '分享标题（通常是动态的）',
//    desc: '分享描述（通常是动态的）'
//});

//导出工具类
export default WxShareSdk;