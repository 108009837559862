/*
 * @Author       : a大师兄
 * @Date         : 2022-07-07 13:45:58
 * @LastEditors  : a大师兄
 * @LastEditTime : 2023-03-14 17:36:27
 * @Description  : 网络相关
 */
export const networkRouters = [
    {
        path: '/404',
        name: '404',
        meta: {
            title: '404'
        },
        component: () => import('../network/404')
    },
    {
        path: '/:pathMatch(.*)',
        redirect: '/404'
    }
];