/*
 * @Author       : a大师兄
 * @Date         : 2022-07-07 09:21:00
 * @LastEditors  : a大师兄
 * @LastEditTime : 2023-02-03 16:28:06
 * @Description  : 常用组件全局注册
 */
import PTabBar from './tabBar/TabBar.vue';
import PComEmpty from './empty/ComEmpty.vue';
import PBanner from './banner/Banner.vue';
export default {
    install(Vue) {
        Vue.component('PTabBar', PTabBar);
        Vue.component('PComEmpty', PComEmpty);
        Vue.component('PBanner', PBanner);
    }
};