/*
 * @Author       : a大师兄
 * @Date         : 2021-03-11 16:59:13
 * @LastEditors  : a大师兄
 * @LastEditTime : 2023-01-30 09:10:04
 * @Description  : API
 */
import { axios } from './axios';

const httpAction = (params) => axios(params),

    // get 方法
    getAction = (url, params) => {
        return axios({
            url: url,
            method: 'get',
            params: params,
            // data:params
        })
    },

    // post
    postAction = (url, params) => {
        return axios({
            url: url,
            method: 'post',
            data: params
        })
    }

export {
    httpAction,
    getAction,
    postAction
};