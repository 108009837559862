/*
 * @Author       : a大师兄
 * @Date         : 2023-10-14 18:27:50
 * @LastEditors  : a大师兄
 * @LastEditTime : 2023-11-01 16:41:27
 * @Description  : 主题配置
 */

const $theme = {
    GradientBottomButton: 'linear-gradient(to bottom, #639CFF, #1A53FF)',
    vLoadingIconColor:'#FBB149'
};

export default $theme;