import Vue from 'vue';
import loading from './vLoading.vue';

const LoadingComponents = Vue.extend(loading),

    insertDom = (parent, el) => {
        parent.appendChild(el.mask);
    },

    toggleLoading = (el, binding) => {
        if (binding.value) {
            Vue.nextTick(() => {
                el.instance.visible = true;
                insertDom(el, el, binding);
            });
        } else {
            el.instance.visible = false;
        }
    };

export default {
    bind(el, binding, vnode) {
        const mask = new LoadingComponents({
            el: document.createElement('div'),
            data() { }
        });

        el.style.position = 'relative';
        el.instance = mask;
        el.mask = mask.$el;
        el.maskStyle = {};
        binding.value && toggleLoading(el, binding);
        console.log(vnode);
    },
    update(el, binding) {
        if (binding.oldValue !== binding.value) {
            toggleLoading(el, binding);
        }
    },
    unbind(el, binding) {
        console.log(binding);
        el.instance && el.instance.$destroy();
    }
};
