/*************************** 全局变量配置文件 ***************************/
const VUE_APP_UNIT = process.env.VUE_APP_UNIT,
    CONFIG = {};

CONFIG.dev = {
    baseURL: '/api',
    appId: '',
    isVConsole: false
};

CONFIG.dat = {
    baseURL: '/pcs-app/app',
    appId: '',
    isVConsole: false
};
CONFIG.prd = {
    baseURL: '/pcs-app/app',
    appId: '',
    isVConsole: false
};

console.log(`欢迎进入${VUE_APP_UNIT}环境`);
export default CONFIG[VUE_APP_UNIT];